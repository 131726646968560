.modal {
  :global {
    .ant-modal-content {
      border: 1px solid #333155;
      background-color: var(--color-primary-default, #181733);
      padding: 0;
      border-radius: 10px;
      .ant-modal-close {
        top: 30px;
        .ant-modal-close-x {
          font-size: 22px;
          color: #fdc52c;
        }
      }
    }
    .ant-modal-header {
      height: 60px;
      background-color: var(--color-primary-default, #181733);
      padding: 20px 24px 10px;
    }
    .ant-divider {
      margin: 15px 0px 30px -24px !important;
      border: 0.7px solid #333155;
      width: 558px;
    }
    .ant-modal-body {
      padding: 0px 24px;

      button {
        height: 40px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .ant-modal-footer {
      padding: 0px 20px 24px 20px;
    }

    .ant-modal-title {
      font-size: 30px;
      font-weight: 800;
      font-style: italic;
      line-height: normal;
      color: #fdc52c;
    }
  }
}

.subheader {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.divider {
  :global {
    .ant-divider {
      .ant-divider-horizontal {
        margin: 0 !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .modal {
    :global {
      .ant-modal-content {
        .ant-modal-close {
          top: 17px;
          .ant-modal-close-x {
            font-size: 22px;
            color: #fdc52c;
          }
        }
      }
      .ant-modal-header {
        height: 60px;
        background-color: var(--color-primary-default, #181733);
        padding: 20px 24px 30px;
      }
      .ant-divider {
        display: none;
      }
      .ant-modal-body {
        padding: 0px 24px;
  
        button {
          height: 35px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
  
      .ant-modal-footer {
        padding: 0px 20px 24px 20px;
      }
  
      .ant-modal-title {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
