:root {
    --gradient-bg: linear-gradient(90deg, #cf122d 0, #154284);

    --gradient-bg-2: linear-gradient(90deg, #CF122D 0, #154284);

    --black: #000000;
    --white: #ffffff;
    --yellow: #FDC52C;
    --gray: #727272;

    --primary_main: #222;
    --secondary_main: #154284;
    --secondary_dark: #181733;
    --dark: #1C1C3A;

    --red: #CF122D;
    --red_dark: #8D1A17;
}
