body:has(.lang:global(.ant-dropdown-open)) {
  .lang {
    background-color: #cd122d;
  }
}

.menu {
  :global {
    .ant-dropdown-arrow {
      top: -12px;
    }
    .ant-dropdown-menu {
      padding: 8px 0px !important;
      top: -12px;
      border-radius: 2px;

      .ant-dropdown-menu-item-group-title {
        font-size: smaller;
        padding: 7px 35px 7px 27px;
        color: rgba(0, 0, 0, 0.75);
      }

      & > .ant-dropdown-menu-item {
        color: var(--black) !important;
        font-weight: 600;
        padding: 7px 35px 7px 27px;
        border-bottom: 1px solid #f7f6f6;
        border-radius: 0px;

        &:hover {
          background-color: #ebe9e9;
        }
      }
      & > .ant-dropdown-menu-item-selected {
        background-color: #fafafa;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 1px;
          width: 8px;
          height: 65%;
          background-color: var(--secondary_main);
        }
      }
    }
  }
}

.lang {
  margin: 0px 15px;
  padding: 5px 7px !important;
  background-color: #292d4c;
  text-transform: uppercase;
  border: none;
  border-radius: 100%;
  font-weight: 600;
  width: 2.3rem;
  height: 2.3rem;
  color: var(--white) !important;

  &:hover {
    background-color: #cd122d;
  }

  &:has(:global(.ant-dropdown-open)) {
    display: none;
  }

  :global {
    .ant-dropdown-open {
      height: 0px !important;
    }
  }
}
