.selectBlock :global(.ant-select-selector) {
  background-color: white !important;
  border: none !important;
}

.selectBlock :global(.ant-select) {
  color: #181733 !important;
  cursor: pointer !important;
  font-weight: 800;
  margin: 0px 60px 20px;
}

.selectBlock :global(.ant-select-selection-item-remove) {
  color: rgba(24, 23, 51, 1) !important;
}

.selectBlock :global(.ant-select-selection-placeholder) {
  color: #181733 !important;
  font-weight: 800;
  display: flex;
}

.selectBlock :global(.ant-select-selection-item) {
  display: flex;
}

.selectBlock :global(.anticon-close) {
  color: #181733 !important;
}

.selectBlock :global(.ant-select-selection-item) {
  color: #181733 !important;
  font-weight: 800;
}

.selectBlock :global(.ant-select-arrow) {
  color: #181733 !important;
  font-weight: 800;
  pointer-events: none;

  & > span {
    pointer-events: none !important;
  }
}

@media (max-width: 700px) {
  .selectBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .selectBlock :global(.ant-select) {
    margin-bottom: 10px;
  }
}
