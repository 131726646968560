.contentBlock {
  border: 1px solid #333155;
  width: 75%;
  margin: 0 auto;
}

.banner {
  width: 100%;
  height: 578px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.img {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 578px;
  object-fit: cover;
  object-position: 0px -40px;
  cursor: pointer;
}

@media (max-width: 1060px) {
  .img {
    object-position: 0px -25px;
  }
}

.img2 {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 578px;
  object-fit: cover;
  object-position: center 0px;
  cursor: pointer;
}

@media (min-width: 1600px) {
  .banner {
    height: 700px;
  }
  .img {
    height: 700px;
  }
  .img2 {
    height: 700px;
  }
}

.button {
  background-color: #fbc43f;
  color: #181733;
  border: none;
  height: 48px;
  width: 158px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #181733 !important;
  }
  &.ky > span {
    font-family: Din Next !important;
  }
}

.title {
  position: relative;
  font-size: 45px;
  font-weight: 800;
  margin: 70px 0px 35px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.collectionBlock {
  margin: 50px auto;
  display: grid;
  justify-content: center;
  gap: 32px;
  padding: 0px 32px;

  &:not(:has(> .notCategory)) {
    @media screen and (min-width: 750px) {
      grid-template-columns: repeat(3, minmax(220px, 417px));
      gap: 16px;
    }
    @media screen and (min-width: 1024px) {
      gap: 32px;
    }
  }
}

@media (max-width: 600px) {
  .collectionBlock {
    margin: 50px auto 32px;
    padding: 0px 16px;
  }
}

.notCategory {
  font-size: 24px;
  margin: 30px auto;
  color: #bfbaba;
}

.breadcrumbs {
  width: 100%;
  height: 10px;
  display: flex;
  background: var(--gradient-bg-2);
  transition: height 0.2s ease;
  box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
}

.breadcrumbs2 {
  width: 100%;
  height: 10px;
  display: flex;
  background: var(--gradient-bg);
  transition: height 0.2s ease;
  box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
}

.searchBlock {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: #0009;
}

.searchContent {
  width: 100%;
  height: 95vh;
  background-color: white;
  padding: 20px;
}

.searchHead {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchHead :global(.ant-input) {
  height: 42px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 2px;
}

.searchHead :global(.ant-input-group-addon) {
  height: 42px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
}

.btn {
  border: none;
}

.logoHeader {
  height: 50px;
}

.search {
  cursor: pointer;
}

.input {
  width: 60%;
}

.cardContent {
  margin-top: 50px;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.might {
  font-size: 24px;
  color: #676d89;
  margin-top: 30px;
  margin-left: 25px;
}

@media (max-width: 600px) {
  .img {
    object-position: center 40px;
  }
  .img2 {
    object-position: center 0px;
  }
  .banner {
    height: 390px;
  }
  .marginUp {
    margin: 94px 0px;
  }
  .logoHeader {
    display: none;
  }
  .input {
    width: 80%;
  }
  .might {
    font-size: 14px;
  }
  .searchContent {
    height: 100vh;
  }
}

@media (max-width: 500px) {
  .banner {
    height: 380px;
  }
  .title {
    font-size: 30px;
  }
  .marginUp {
    margin: 98px 0px;
  }
}
