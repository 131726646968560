.input {
  background-color: #181733;
  height: 44px;
  color: white;
  border-radius: 6px;
  border: 1px solid #333155;
  width: 498px;
  padding-left: 10px;
}

.input :global(.ant-input) {
  color: white;
  background-color: #181733;
}

.input :global(.ant-input::placeholder) {
  color: rgb(255, 254, 254);
}

@media (max-width: 600px) {
  .input {
    height: 44px;
    width: 100%;
  }
}
