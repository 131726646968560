.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 260px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .mainButton {
    width: 100%;
  }
  .buttonsContainer {
    width: 100%;
  }
}
