.mainBlock {
  border-radius: 0px 0px 40px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
}

.pageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -42px;
  background-color: white;
}

@media (max-width: 550px) {
  .pageBlock {
    margin-top: 0px;
  }
}

.btn2 {
  background-color: #fbc43f;
  color: #181733;;
  border: none;
  height: 48px;
  width: 100%;
  font-weight: 600;
  margin-top: 30px;

  &:hover {
    color: rgba(0, 0, 0, 1) !important;
  }
}

.button {
  width: 100%;
  background-color: rgba(253, 197, 44, 1);
  color: rgba(0, 0, 0, 1);
  font-style: italic;
  height: 44px;
  border: none;
  font-weight: 800;
  margin-top: 24px;

  &:hover {
    color: rgba(0, 0, 0, 1) !important;
  }
}

.img {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

.contentBlock {
  display: flex;
  justify-content: space-between;
  margin: 100px 20px 50px;
}

.card {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  width: 100%;
}

.cardBlock {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 610px) {
  .mainBlock {
    border: none;
    width: 100%;
  }
}

@media (max-width: 1320px) {
  .contentBlock {
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
  }
  .cardBlock {
    width: 100%;
  }
}

.cardContent {
  display: flex;
}

.name {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
}

.res {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
}

.id {
  font-weight: 400;
  color: rgba(137, 137, 137, 1);
  font-size: 14px;
  margin-top: 5px;
}

.nameBlock {
  margin-left: 20px;
}

.closeBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.close {
  display: flex;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  cursor: pointer;
}

.priceBlock {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 450px;
  height: 250px;
  border-radius: 4px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
}

.priceRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.total {
  font-size: 16px;
  color: rgba(70, 69, 92, 1);
  font-weight: 400;
}

.price {
  font-weight: 600;
  color: rgba(253, 197, 44, 1);
  font-size: 20px;
}

.rightBlock {
  width: 50%;
  display: flex;
  justify-content: center;
}

.input {
  width: 80px;
  border: none;
  border-radius: 4px;
  background-color: #46455C;
  height: 30px;
  margin-top: 35px;
  color: white;
  text-align: center;
}

@media (max-width: 490px) {
  .input {
    margin-top: 10px;
  }
  .img {
    width: 100px;
    height: 100px;
  }
  .priceBlock {
    width: 370px;
  }
  .nameBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .name {
    font-size: 15px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .contentBlock {
    margin-top: 20px;
  }
}

@media (max-width: 360px) {
  .name {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .priceBlock {
    width: 310px;
    padding: 20px 20px;
  }
}
