.loginBlock {
  text-align: center;
}

.loginTitle {
  font-size: 30px !important;
  font-style: italic;
  color: #fdc52c !important;
  margin: 20px 0px 40px !important;
}

.logo {
  width: 56px;
}
