.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(0deg, #000000e6 0rem, rgba(11, 11, 12, 0) 50%);
  }

  &:nth-child(3) {
    & > .title {
      max-width: 18.5rem;
    }
  }

  @media (min-width: 840px) {
    &:hover {
      .img {
        transform: scale(1.1);
        filter: brightness(0.8);
      }
    }
  }
}

.img {
  max-width: 100%;
  height: auto;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  transition: all 0.3s;
}

@media (min-width: 1000px) {
  .img:hover {
    transform: scale(1.1);
    filter: brightness(0.7);
  }
}

@media (max-width: 500px) {
  .title {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  position: absolute;
  z-index: 3;
  bottom: 0px;
  left: 0px;
  padding: 30px 20px;
}

.ex {
  color: #fbc43f;
  font-size: 20px;
  font-weight: 600;
}

.title {
  color: #ffff;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
}
