.title {
  font-size: 90px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  transform: scaleY(2);
  position: absolute;
  bottom: 70px;
  left: 60px;
}

.pagination {
  padding-bottom: 50px;
}

.mainBlock {
  width: 100%;
  text-align: center;
  background: white;
}

.block {
  position: relative;
}

.cardBlcok {
  margin-top: 30px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.filter {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.spin {
  width: 100%;
  display: flex;
  justify-content: center;
}

.emptyDesc {
  color: var(--secondary_dark);
  font-size: 18px;
  margin-bottom: 30px;
}

.categoryImage {
  width: 100%;
  height: 600px;
  object-fit: cover;
  object-position: 0px 0px;
  filter: brightness(85%);
}

@media (max-width: 1000px) {
  .categoryImage {
    object-position: center;
  }
}

@media (max-width: 740px) {
  .title {
    font-size: 50px;
    left: 10px;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: 35px;
    left: 10px;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
  }
  .categoryImage {
    height: 400px;
    margin-top: -18px;
  }
  .cardBlcok {
    justify-content: center;
  }
}
