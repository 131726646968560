.forgotPass {
  cursor: pointer;
  margin: 0 10px;
  color: #403e61;
}

.logo {
  position: relative;
  top: 7px;
}

.btn {
  cursor: pointer;
  margin: 0 10px;
  color: #403e61;
  height: 32px;
  font-size: 14px !important;
  border: none;
  background-color: #181733;
  padding: 0;
}

.timer {
  text-align: center;
}
