.mainBlock {
  border-radius: 0px 0px 40px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
}

@media (max-width: 500px) {
  .mainBlock {
    border: none;
    width: 100%;
  }
}

.img {
  width: 530px !important;
  height: 530px !important;
}

.pageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 67px;
}

.contentBlock {
  display: flex;
  justify-content: space-between;
  padding: 40px;
}

@media (max-width: 430px) {
  .contentBlock {
    padding: 0px 20px 30px;
  }
}

@media (max-width: 360px) {
  .contentBlock {
    padding: 0px 10px 30px;
  }
}

.smallImg {
  // margin-bottom: 10px;
  width: 165px !important;
  height: 165px !important;
  object-fit: contain;
}

.imgBlock {
  display: flex;
  width: 67%;
}

.descBlock {
  width: 33%;
}

.smallImgBlock {
  display: flex;
  flex-direction: column;
  width: 165px;
  margin: 0 20px;
}

.bigImgBlock :global(.ant-image .ant-image-img) {
  width: 530px !important;
  height: 530px !important;
  object-fit: contain;
}

@media (max-width: 1420px) {
  .contentBlock {
    flex-direction: column;
  }
  .descBlock {
    margin-top: 30px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .imgBlock {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .contentBlock {
    flex-wrap: wrap;
  }
}

@media (max-width: 1000px) {
  .imgBlock {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  .smallImgBlock {
    flex-direction: row;
    justify-content: center;
  }
  .smallImg {
    margin: 10px;
  }
}


.smallImgBlock :global(.ant-image .ant-image-img) {
  width: 165px !important;
  height: 165px !important;
}

.smallImgBlock :global(.ant-image) {
  margin-bottom: 10px;
}

@media (max-width: 770px) {
  .smallImg {
    width: 100px !important;
    height: 100px !important;
  }
  .smallImgBlock :global(.ant-image .ant-image-img) {
    width: 100px !important;
    height: 100px !important;
  }
  .img {
    width: 330px !important;
    height: 330px !important;
  }
  .bigImgBlock :global(.ant-image .ant-image-img) {
    width: 330px !important;
    height: 330px !important;
  }
  .descBlock {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .smallImg {
    width: 80px !important;
    height: 80px !important;
  }
  .smallImgBlock :global(.ant-image .ant-image-img) {
    width: 80px !important;
    height: 80px !important;
  }
  .img {
    width: 300px !important;
    height: 300px !important;
  }
  .bigImgBlock :global(.ant-image .ant-image-img) {
    width: 300px !important;
    height: 300px !important;
  }
}

.name {
  font-weight: 800;
  color: white;
  font-size: 28px;
  margin-bottom: 30px;
}

.title2 {
  margin-top: 40px;
  font-weight: 800;
  color: white;
  font-size: 32px;
  margin-bottom: 30px;
}

.details {
  font-weight: 600;
  color: rgba(137, 137, 137, 1);
  font-size: 14px;
  margin-bottom: 10px;
}

.desc {
  font-weight: 400;
  color: rgba(137, 137, 137, 1);
  font-size: 14px;
  margin-bottom: 30px;
}

.priceBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.oldPriceBlock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.oldPrice {
  font-weight: 600;
  font-style: italic;
  font-size: 18px;
  color: red;
  text-decoration: line-through;
}

.price {
  font-weight: 800;
  font-style: italic;
  font-size: 24px;
  color: white;
}

.tovar {
  font-weight: 800;
  font-style: italic;
  font-size: 24px;
  color: white;
  margin-top: 50px;
}

.button {
  width: 100%;
  background-color: rgba(253, 197, 44, 1);
  color: rgba(0, 0, 0, 1);
  font-style: italic;
  height: 44px;
  border: none;
  font-weight: 800;

  &:hover {
    color: black !important;
  }
}

.button[disabled] {
  color: white;

  &:hover {
    color: white !important;
  }
}

.selectBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
}

.selectBlock :global(.ant-select-selector) {
  background-color: var(--secondary-dark) !important;
  border: 1px solid rgba(70, 69, 92, 1) !important;
}

.selectBlock :global(.ant-select) {
  color: white !important;
  cursor: pointer !important;
  font-weight: 800;
}

.selectBlock :global(.ant-select-selection-item-remove) {
  color: white !important;
}

.selectBlock :global(.ant-select-selection-placeholder) {
  color: white !important;
  font-weight: 800;
}

.selectBlock :global(.ant-select-selection-item) {
  color: white !important;
  font-weight: 800;
}

.selectBlock :global(.ant-select-arrow) {
  color: rgba(70, 69, 92, 1) !important;
  font-weight: 800;
}

.recommend {
  background: linear-gradient(90deg, rgba(21, 66, 132, 1) 0, rgba(207, 18, 45, 1));
  height: 710px;
  width: 100%;
  border-radius: 0px 0px 38px 38px;
}

.recContent {
  background-image: url("../../../../public/icons/rec.svg");
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardList {
  display: flex;
  justify-content: space-around;
}

.card {
  cursor: pointer;
  width: 340px;
  height: 270px;
  margin: auto;
  background-color: white;
  transition: filter 0.3s;
}

.card:hover {
  filter: brightness(0.7);
}

.cardImg {
  width: 340px;
  height: 210px;
  object-fit: contain;
}

.cardContent {
  height: 40px;
  padding-left: 20px;
}

@media (max-width: 360px) {
  .card {
    width: 310px;
  }
  .cardImg {
    width: 310px;
  }
}

.cardName {
  color: black;
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
  background: linear-gradient(90deg, #CF122D, #154284);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ограничиваем содержимое двумя строками */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
  line-height: 22px; /* Высота строки должна соответствовать размеру шрифта */
  height: 44px; /* Максимальная высота, чтобы вместить ровно две строки */
  width: 100%; /* Ширина блока может быть изменена по вашему желанию */
  box-sizing: border-box;
}
