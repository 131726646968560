.mainBlock {
  border-radius: 0px 0px 40px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  padding: 40px 60px 150px;
  text-align: center;
}

.form {
  margin-top: 50px;
  width: 75%;
}

@media (max-width: 1200px) {
  .form {
    width: 100%;
  }
}

@media (max-width: 610px) {
  .mainBlock {
    border: none;
    width: 100%;
    padding: 40px 60px 40px;
  }
}

@media (max-width: 435px) {
  .mainBlock {
    padding: 20px;
  }
}

.pageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  margin-top: -42px;
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 800;
  background: linear-gradient(90deg,#CF122D, #154284);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 60px;
  margin-top: 35px;
}

.phone :global(.react-tel-input .form-control) {
  background: white !important;
  color: #333155 !important;
  border: 1px solid #333155 !important;
  height: 44px !important;
}

.phone :global(.selected-flag) {
  background: white !important;
}

.phone :global(.flag-dropdown) {
  border: 1px solid #333155 !important;
  border-radius: 10px !important;
}

.phone :global(.country-list) {
  background: #181733 !important;
  color: white !important;
}

.phone :global(.country-list li) {
  display: flex;
  justify-content: flex-start;
}

.phone :global(.country-list li:hover) {
  background: #CF112C !important;
}

.phone :global(.react-tel-input .country-list .country.highlight) {
  background-color: #CF112C !important;
}

.emailBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.emailBlock :global(.ant-form-item) {
  width: 49%;
}

.buttonBlock {
  display: flex;
  justify-content: flex-end;
}

.selectBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: start;
}

.selectBlock :global(.ant-form-item) {
  width: 100%;
}

.selectBlock :global(.ant-select-single) {
  height: 44px !important;
}

.selectBlock :global(.ant-select-selector) {
  background-color: var(--secondary-dark) !important;
  border: 1px solid rgba(70, 69, 92, 1) !important;
}

.selectBlock :global(.ant-select) {
  color: white !important;
  cursor: pointer !important;
  font-weight: 400;
}

.selectBlock :global(.ant-select-selection-item-remove) {
  color: white !important;
}

.selectBlock :global(.ant-select-selection-placeholder) {
  color: rgb(182, 182, 182) !important;
  font-weight: 400;
}

.selectBlock :global(.ant-select-selection-item) {
  color: #333155 !important;
  font-weight: 400;
}

.selectBlock :global(.ant-select-arrow) {
  color: rgba(70, 69, 92, 1) !important;
  font-weight: 800;
}

.contentBlock {
  display: flex;
}

@media (max-width: 500px) {
  .title {
    margin-bottom: 30px;
    margin-top: 70px;
  }
}
