.headerLogin {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0px;
}

.loginButton {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg,#8c1a17,#cd122d);
  height: 40px;
  border-radius: 4px;
  border: none;
  color: white !important;
  margin-right: 10px;
  font-weight: 700;
  padding: 15px 22px;
}

.imgButton {
  width: 20px;
}

.registerButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: white !important;
  margin-right: 10px;
  font-weight: 700;
  height: 40px;

  &:hover > span {
    text-decoration: underline;
  }

  :global {
    .anticon-user {
      background-color: var(--secondary_main);
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 100%;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
      display: flex;
      justify-content: center;
    }
  }
}

.email {
  font-weight: 800;
  background-color: #001529;
  border: none;
  color: white;
  margin-right: 30px;
  height: 40px;

  &:hover {
    color: white !important;
  }
}

.badge {
  position: relative;
  margin-top: -7px;
  margin-right: 14px;
  cursor: pointer;

  & :global(.ant-badge-count) {
    padding: 0px 4px;
  }
}
