.input {
  background-color: white;
  border-color: #333155;
  height: 44px;
}

.input :global(.ant-input) {
  color: #333155 !important;
  background-color: white;
}

.input :global(.ant-input::placeholder) {
  color: rgb(182, 182, 182) !important;
}
