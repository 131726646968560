@import "../../theme/mixins.scss";


.background {
  background-image: url("../../../public/images/moreLines.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 186px;
  display: grid;
  align-content: center;

  @include responsive(desktop) {
    height: 160px;
  }

  @include responsive(phone) {
    height: 150px;
  }
}

.logo {
  background-image: url("../../../public/images/footerLogo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50px;

  @include responsive(desktop) {
    transform: scale(0.8);
  }

  @include responsive(phone) {
    transform: scale(0.75);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bigTitle {
  font-weight: 640;
  font-size: 14.5vw;
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: all 0.3s;
  transform: scaleY(1.8);
  cursor: default;
  user-select: none;
  margin-top: 30px;
  margin-bottom: 65px;
}

@media (max-width: 1000px) {
  .bigTitle {
    background: linear-gradient(165deg, #ef1f46, #0068ff);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientAnimation 3s ease infinite;
  }
}

.youtube {
  background-color: rgba(237, 30, 36, 1);
  width: 160px;
  padding: 10px 15px;
  text-align: center;
  color: white;
  cursor: pointer;
  height: 100%;
}

.footer {
  background-color: var(--secondary_dark);
  padding: 0px !important;
  overflow: auto;

  &:hover {
    @media (min-width: 1000px) {
      .bigTitle {
        background: linear-gradient(165deg, #ef1f46 7%, #0068ff 88%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }
}

.text {
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.socialMedia {
  margin-top: 40px;
  text-align: center;
}

.socialNetworks {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6px;

  @include responsive(phone) {
    margin-top: 20px;
  }

  & :global {
    .anticon {
      font-size: 2rem;
    }
  }

  & > a {
    border: 2px solid transparent;
    transition: all .2s linear;
    height: 110px;

    &:hover {
      border: 2px solid var(--white);
    }
  }
}

.facebook {
  background-color: #3b5998;
  width: 160px;
  padding: 10px 15px;
  text-align: center;
  color: white;
  cursor: pointer;
  height: 100%;
}

.twitter {
  background-color: rgba(85, 172, 238, 1);
  width: 160px;
  padding: 10px 15px;
  text-align: center;
  color: white;
  cursor: pointer;
  height: 100%;
}

.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d);
  width: 160px;
  padding: 10px 15px;
  text-align: center;
  color: white;
  cursor: pointer;
  height: 100%;
}

.title {
  margin-top: 15px;
  font-weight: 400;
  font-size: 10px;
}

.url {
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 30px;
  text-align: start;
  font-weight: 600;
  font-size: 12px;
}

.breadcrumbs {
  margin-top: 15px;
  width: 100%;
  height: 15px;
  display: flex;
  background: var(--gradient-bg);
  transition: height 0.2s ease;
  box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
}

@media (max-width: 360px) { 
  .breadcrumbs {
    padding: 0px 160px;
  }
}

.footerCorporate {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 30px;
  color: var(--white);
  font-size: 13px;
  font-weight: 400;
  padding: 0 16px;
  margin-top: 150px;

  @include responsive(phone) {
    font-size: 11px;
    margin-top: 80px;
  }

  & > &Container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include responsive(tablet) {
      flex-direction: column;
      align-items: baseline;
      text-align: left;
      gap: 20px;
    }

    & > div > span {
      color: var(--white);
      font-weight: 400;
    }
  }

  & &links {
    & > a {
      margin-right: 20px;
      position: relative;

      @include responsive(tablet) {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 6px 0px;
      }

      @media (min-width: 760px) {
        &:not(:last-child)::after {
          content: "|";
          position: absolute;
          right: -10px;
        }

        &:hover {
          color: var(--white);
          text-decoration: underline;
          text-decoration-color: var(--white);
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .text {
    font-size: 18px;
  }
}
