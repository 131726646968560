@import "../../theme/mixins.scss";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 119px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 0px;
  color: var(--white);
  transition: all 0.3s 0.1s ease-in-out;

  &.hide {
    top: -112px;
  }
}

@media (max-width: 1000px) {
  .header {
    height: 67px;
  }
}

.nav_top {
  padding: 0 25px;
  height: 49px;
  background-color: #0a0927;
}

.nav_middle {
  display: flex;
  align-items: center;
  height: 62px;
  padding: 0 25px;
  background-color: var(--secondary_dark);
}

.logo {
  line-height: normal;
  margin-right: 15px;
}

.menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu_left {
  display: flex;
  align-items: center;
  color: #fff;
  &:hover > .link:not(:hover) {
    color: #aaa;
  }
  & > .link.active {
    color: var(--white);
  }
}

.menu_right {
  display: flex;
  align-items: center;
  position: absolute;
  right: 145px;
  color: var(--yellow);
  &:hover > .link:not(:hover) {
    color: #aaa;
  }
  &:hover > .link:hover {
    color: var(--yellow) !important;
  }
  & > .link.active {
    color: var(--yellow) !important;
  }
}

.icon {
  color: white;
}

.link {
  padding: 0 20px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent !important;
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 5px;

  :global {
    .MuiSvgIcon-root:first-child {
      font-size: large;
    }
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .anticon-caret-down {
      font-size: xx-small !important;
    }
  }
  &.active::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 0.35rem;
    background: var(--yellow);
  }
  &:focus {
    background-color: rgba(128, 127, 127, 0.1) !important;
  }
}

.dropdown {
  :global {
    .ant-dropdown-arrow {
      top: -22px;

      &::before {
        background-color: var(--secondary_main);
      }
    }
    .ant-dropdown-menu {
      top: -22px;
      background-color: var(--secondary_main);
      border-radius: 2px;
      padding: 8px 4px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid (--secondary_main);
        transform: translate(-50%, -50%);
      }
      &-item {
        color: var(--white) !important;
        font-size: 15px !important;
        font-weight: 600;
        text-transform: capitalize;
      }
      &:not(:has(.ant-dropdown-menu-item:only-child)) {
        .ant-dropdown-menu-item {
          padding: 7px 12px !important;
        }
      }
    }
  }
}

.searchHeader {
  display: flex;
  justify-content: space-between;
  width: 80px;
}

.iconButton {
  &:hover {
    color: white !important;
  }
}

.cartBadge {
  position: absolute;
  top: -14px;
  right: -3px;
  background: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.additionalBlock {
  position: absolute;
  top: 58px;
  z-index: 1212;
  height: 100vh;
  width: 100%;
}

.innerHeader {
    padding-left: 32px;
    // padding-top: 30px;
    align-items: center;
    width: 100%;
    display: flex;
    button {
      border: none;
    }
  }
  
  .background {
    position: absolute;
    height: 100vh;
    background-repeat: no-repeat;
    z-index: 1;
    width: 100%;
    overflow: hidden !important;
    background-color: none;
    button {
      border: none;
    }
    &::before {
      content: "";
      background: linear-gradient(90deg, #cd122d 0, #154284) !important;
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 1;
      background-position: top; /* Set background position for top half */
    }
  }

  .styleLine {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
    background: linear-gradient(90deg, #cd122d 0, #154284);
  }

  .buttons {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
  }
  
  .registerButton {
    font-weight: 800;
    font-size: 20px;
    z-index: 899;
    background-color: #001529;
    border: none;
    color: white;
    margin-right: 10px;
    height: 40px;
  }
  
  .buttonSelector {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  .innerItemsBack {
    padding: 6px 15px 30px 18px;
  }
  .blockBorder {
    padding-bottom: 13px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
    width: 100%;
  
    & li > a {
      display: block;
      width: 100%;
      color: var(--white);
    }
  }
  .blockBorder2 {
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
    width: 100%;
  }
  .closeIcon {
    font-size: 22px;
    color: var(--white);
    position: relative;
    cursor: pointer;
  }
  
  .academy {
    color: #fdc52c !important;
    font-weight: 700;
    text-align: left !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    & > a {
      color: #fdc52c !important;
    }
  
    & > span {
      font-size: smaller;
      margin-right: 20px;
    }
  }

  .academy2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
  
    & > span {
      font-size: smaller;
      margin-right: 20px;
    }
  }
  
  .middleBlock {
    padding: 0 10px;
    // padding-left: 30px;
    // padding-top: 30px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
    }
  }
  .smallMenu {
    display: flex;
    position: absolute;
    right: 12px;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }
  .logoSmall {
    margin-top: 10px;
  }
  .listItems {
    text-transform: uppercase;
    z-index: 300;
    display: flex;
    flex-direction: column;
    column-gap: 11px !important;
    align-items: flex-start;
  
    li {
      z-index: 300;
      width: 100%;
      margin-top: 11px;
      color: #fff;
      text-align: left;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
  
  .loginButton {
    font-size: 20px;
    z-index: 899;
    width: 200px;
    background-color: #cc092f;
    height: 40px;
    border-radius: 4px;
    border: none;
    color: white;
    margin-right: 10px;
    font-weight: 800;
    font-style: italic;
  }
  
  .bottomNav {
    max-height: 54px;
    display: flex;
    background: #181733;
    justify-content: space-around;
    font-size: 16px;
    text-transform: uppercase;
    color: #fdc52c;
    align-items: center;
    font-weight: 600;
    ::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 5px;
      box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
      background: linear-gradient(90deg, #cd122d 0, #154284);
    }
  }
  
  .circle {
    align-items: center;
    background-color: hsla(0, 0%, 100%, 0.12);
    border-radius: 100%;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    height: 2.6rem;
    justify-content: center;
    transition: background-color 0.3s ease-in;
    width: 2.6rem;
    display: flex;
    flex-direction: column;
  }
  
  .icon {
    height: 1.2px;
    background: #fff;
    border-radius: 0.1rem;
    width: 1.3rem;
  }
  .shopCard {
    height: 0px !important
  }
  .iconMiddle {
    height: 1.2px;
    background: #fff;
    border-radius: 0.1rem;
    width: 1.3rem;
    position: relative;
    margin: 0.3rem 0;
  }
  .culers {
    color: white;
    font-weight: 600;
    background: linear-gradient(90deg, #8c1a17, #cd122d);

    &:hover {
      color: white !important;
    }
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-top: 6px solid transparent; /* Adjust the size as needed */
    border-bottom: 6px solid transparent; /* Adjust the size as needed */
    border-right: 8px solid white; /* Adjust the color and size as needed */
  }
  .blockBorder3 {
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
    width: 100%;
    display: flex;
    align-items: baseline;
    column-gap: 5px;
    // opacity: 0.6;
    background: hsla(0, 0%, 100%, 0.08);
    margin-top: -2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .dropDownMenu {
    background-color: #181733;
    position: absolute;
    width: 100%;
    height: 450px;
    top: 110px;
    display: flex;
    justify-content: space-between;
  }

  .collections {
    width: 67%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 0 20px;
    overflow-y: auto;
    margin-bottom: 20px;
  }

  .scrollBlock {
    overflow-y: auto;
    width: 100%;
    max-height: 600px;
  }

  .scrollBlock::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }
  
  .scrollBlock::-webkit-scrollbar-track {
    background: #666;
  }
  
  .scrollBlock::-webkit-scrollbar-thumb {
    background-color: #fbc43f;
    border-radius: 5px;
    cursor: grab;
  }

  .collections::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }
  
  .collections::-webkit-scrollbar-track {
    background: #666;
  }
  
  .collections::-webkit-scrollbar-thumb {
    background-color: #fbc43f;
    border-radius: 5px;
    cursor: grab;
  }

  @media (max-width: 1300px) {
    .collections {
      width: 95%;
    }
  }

  .colName {
    margin: 10px 30px;
  }

  .elem {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
  }

  .item {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
  }

  .item:hover {
    color: #fbc43f;
  }

  .elem:hover {
    color: #fbc43f;
  }

  .colImage {
    margin: 30px;
    width: 340px;
    height: 380px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
      filter: brightness(0.8);
    }
  }
  
  .searchBlock {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    background-color: #0009;
  }
  
  .searchContent {
    width: 100%;
    height: 95vh;
    background-color: white;
    padding: 20px;
  }
  
  .searchHead {
    margin: 0 auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .searchHead :global(.ant-input) {
    height: 42px;
    border: none;
    background-color: #f1f1f1;
    border-radius: 2px;
  }
  
  .searchHead :global(.ant-input-group-addon) {
    height: 42px;
    border: none;
    background-color: #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
  }
  
  .btn {
    border: none;

    &:hover {
      color: black !important;
    }
  }
  
  .logoHeader {
    height: 50px;
  }
  
  .search {
    cursor: pointer;
  }
  
  .input {
    width: 60%;
  }
  
  .cardContent {
    margin-top: 50px;
    display: flex;
    width: 100%;
    overflow-x: auto;
  }
  
  .might {
    font-size: 24px;
    color: #676d89;
    margin-top: 30px;
    margin-left: 25px;
  }
  
  @media (max-width: 1400px) { 
    .collectionBlock {
      margin: 0px auto;
      justify-content: center;
    }
  }
  
  @media (max-width: 600px) { 
    .banner {
      height: 390px;
    }
    .marginUp {
      margin: 94px 0px;
    }
    .logoHeader {
      display: none;
    }
    .input {
      width: 80%;
    }
    .might {
      font-size: 14px;
    }
    .searchContent {
      height: 100vh;
    }
  }
  
  @media (max-width: 500px) { 
    .banner {
      height: 380px;
    }
    .title {
      font-size: 30px;
    }
    .marginUp {
      margin: 98px 0px;
    }
  }
  