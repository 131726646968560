@keyframes runningText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee {
  margin-top: 2px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  height: 90px;
  font-size: 70px;
  background-color: #181733;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-style: solid;
  border-width: 2px 0px;
  border-image: linear-gradient(165deg, #ef1f46 7%, #0068ff 88%) 1;
  transform: scaleY(1.6);
}

@media (max-width: 600px) { 
  .marquee {
    margin-top: 100px;
  }
}

.marqueeContent {
  display: flex;
  animation: runningText 15s linear infinite;
  background: linear-gradient(165deg, #ef1f46 7%, #0068ff 88%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

.marqueeContent::after,
.marqueeContent::before {
  content: attr(data-text);
  padding: 0 2rem;
}

.marqueeContent::before {
  flex-shrink: 0;
}
