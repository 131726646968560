.card {
  position: relative;
  width: 340px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;

  @media (min-width: 840px) {
    &:hover {
      .img {
        transform: scale(1.1);
        filter: brightness(0.8);
      }
    }
  }
}

.img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
}

.cardContainer {
  margin: 30px;
  overflow: hidden;
  border-radius: 10px;
  height: 380px;
}

.ex {
  color: #fbc43f;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  z-index: 3;
  bottom: 70px;
  left: 20px;
}

.title {
  color: #ffff;
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  z-index: 3;
  bottom: 20px;
  left: 20px;
}
