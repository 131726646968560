@import "../../../theme/mixins.scss";

.container {
    max-width: 1100px;
    margin: 0 auto;
}

.faq {
    background-color: var(--white);
    color: var(--primary_main);
    padding: 0px 15px 32px;

    @include responsive(phone) {
        padding: 0px 12px 32px;
    }
}

.header {
    position: relative;
    text-align: center;
    padding-top: 16px;
    z-index: 1;

    @include responsive(tablet) {
        padding-top: 32px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../../../public/images/trianglesBlack.png");
        background-position: -40px 62%;
        opacity: 0.03;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));
    }
}

.title {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 5px;
    background: var(--gradient-bg);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.ru {
        margin-top: 5px;
        font-family: Din Next !important;
        line-height: 50px;

        @include responsive(phone) {
            font-size: 28px;
            line-height: 28px;
        }
    }
    @include responsive(averageDesktop) {
        font-size: 40px;
    }
    @include responsive(phone) {
        font-size: 28px;
        line-height: 28px;
    }
}

.subTitle {
    color: var(--gray);
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 60px;

    @include responsive(phone) {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 40px;
    }
}

.content {
    width: 100%;
    padding-top: 45px;
    border-top: 1px solid #eaeaea;
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
}

.collapseBlock {
    display: grid;
    gap: 25px;

    & > .heading {
        position: relative;
        margin-top: 15px;
        text-align: center;

        @include responsive(phone) {
            margin-bottom: 15px;
        }

        & > .btnBack {
            position: absolute;
            left: 0;
            top: 25%;

            @include responsive(phone) {
                margin-top: 5px;
                font-size: 10px;
                padding: 4px 10px;
            }

            &:hover {
                border: 1px solid var(--secondary_dark);
                color: var(--secondary_main);
            }
        }

        & > .title {
            font-size: 40px;
            text-align: center;

            @include responsive(phone) {
                margin-top: -25px;
                font-size: 24px;
                line-height: 28px;
            }
        }
    }

    :global {
        .ant-collapse {
            background-color: rgba(0, 0, 0, 0.015);
            display: grid;
            gap: 2px;

            & > .ant-collapse-item {
                & > .ant-collapse-header {
                    background: linear-gradient(90deg, #352675 0, #cf122d);
                    border-radius: 5px;
                    color: var(--white);
                    font-size: 23px;
                    display: flex;
                    align-items: center;

                    @include responsive(tablet) {
                        font-size: 22px;
                    }
                    @include responsive(phone) {
                        font-size: 18px;
                        padding: 8px 12px;
                    }
                }
                & > .ant-collapse-content {
                    font-size: 1.45em;

                    @include responsive(tablet) {
                        font-size: 1.3em;
                    }
                    @include responsive(phone) {
                        font-size: 1.1em;
                    }

                    & > .ant-collapse-content-box {
                        padding-top: 16px;

                        @include responsive(phone) {
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}

.faqItem {
    width: 33.3%;
    height: 250px;
    padding: 0 15px 25px !important;
    cursor: pointer;

    @include responsive(averageDesktop) {
        width: 50%;
        height: 360px;
    }
    @include responsive(tablet) {
        width: 100%;
        height: calc(100vw - 140px) !important;
        padding: 0 30px 25px !important;
    }

    & > .faqLink {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 0 15px 3px rgba(62, 60, 98, 0.08);
        transition: all 0.2s ease-in-out;
        background-color: var(--white);

        &:hover {
            box-shadow: 0 0 15px 5px rgba(77, 74, 143, 0.2);
        }

        & > .faqImage {
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: auto 120px;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        & > .faqContent {
            position: absolute;
            bottom: 6px;
            width: 100%;
            transition: background 0.8s ease;

            & > .faqLabel {
                background: var(--gradient-bg);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 19px;
                font-weight: 600;
                text-align: center;
                padding: 10px;
            }
        }
    }
}
