.card {
  height: 570px;
  width: 24.7%;
  margin: 2px;
  background-color: white;
  position: relative;
  z-index: 2;
  cursor: pointer;
  border: 1px solid #ededed;
}

.details {
  height: 570px;
  width: 350px;
  margin: 2px;
  background-color: white;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.img {
  height: 470px;
  width: 100%;
  object-fit: cover;
}

.detailsImg {
  height: 470px;
  width: 100%;
  object-fit: cover;
}

.content {
  text-align: start;
  padding: 4px 15px;
}

.name {
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 400;
  -webkit-line-clamp: 2; /* Ограничиваем содержимое двумя строками */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
  line-height: 22px; /* Высота строки должна соответствовать размеру шрифта */
  height: 30px; /* Максимальная высота, чтобы вместить ровно две строки */
  width: 100%; /* Ширина блока может быть изменена по вашему желанию */
  box-sizing: border-box;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desc {
  color: rgba(170, 170, 170, 1);
  font-size: 14px;
  font-weight: 400;
}

.price {
  color: rgba(207, 18, 45, 1);
  font-size: 20px;
  font-weight: 800;
  padding-top: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.button {
  background: linear-gradient(to right, rgba(21, 66, 132, 1), rgba(207, 18, 45, 1));
  height: 40px;
  font-weight: 800;
  font-style: italic;
  font-size: 18px;
  color: white;
}

.cart {
  position: absolute !important;
  right: 10px;
  top: 10px;
  color: black;
  cursor: pointer;
}

@media (max-width: 1330px) {
  .card {
    width: 24.6%;
  }
}

@media (max-width: 1000px) {
  .card {
    width: 49%;
  }
}

@media (max-width: 600px) {
  .card {
    height: 470px;
  }
  .img {
    height: 370px;
  }
}

@media (max-width: 500px) {
  .card {
    height: 400px;
  }
  .img {
    height: 300px;
  }
}

@media (max-width: 400px) {
  .card {
    width: 48%;
  }
}
