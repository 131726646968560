.input {
  background-color: transparent;
  border-color: #c4c2c2;
  height: 44px;

  &:not(:global(.ant-input-affix-wrapper-status-error)):not(:disabled):hover,
  &:not(:global(.ant-input-affix-wrapper-status-error)):focus {
    box-shadow: none;
    // border: 1px solid var(--gray) !important;
  }

  :global {
    .ant-input-affix-wrapper:hover {
      border: 1px solid var(--gray);
    }
    .ant-input-clear-icon {
      color: var(--red);

      &:hover {
        color: var(--red_dark);
      }
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
}

.input :global(.ant-input) {
  color: white;
  background-color: transparent;
}

.input :global(.ant-input::placeholder) {
  color: rgb(182, 182, 182) !important;
}

@media (max-width: 600px) {
  .input {
    height: 44px;
    width: 100%;
  }
}
