.btn {
  border: none;
  background-color: var(--secondary_dark);
  padding: 0px;
  color: var(--yellow);
  font-weight: 600;
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}

.emailForm {
  margin-bottom: 0px;
}

.notEmail {
  margin-bottom: 24px !important;
}
