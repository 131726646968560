.mainBlock {
  border-radius: 0px 0px 40px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
  text-align: center;
  padding: 40px 60px 150px;
}

.pageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -42px;
}

.button {
  width: 100%;
  background-color: rgba(253, 197, 44, 1);
  color: rgba(0, 0, 0, 1);
  font-style: italic;
  height: 44px;
  border: none;
  font-weight: 800;
  margin-top: 24px;
}

.img {
  width: 160px;
  height: 160px;
}

.contentBlock {
  margin: 30px 20px 50px;
}

.card {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
}

.cardBlock {
  width: 50%;
}

@media (max-width: 1000px) {
  .mainBlock {
    border: none;
    width: 100%;
    padding: 40px 10px 70px;
  }
}

@media (max-width: 610px) {
  .mainBlock {
    padding: 40px 5px 70px;
  }
  .contentBlock {
    margin: 0px;
  }
}

@media (max-width: 1200px) {
  .contentBlock {
    flex-direction: column;
    align-items: center;
  }
  .cardBlock {
    width: 100%;
  }
}

.cardContent {
  display: flex;
}

.name {
  font-weight: 600;
  color: white;
  font-size: 20px;
}

.res {
  font-weight: 600;
  color: white;
  font-size: 16px;
}

.id {
  font-weight: 400;
  color: rgba(137, 137, 137, 1);
  font-size: 14px;
  margin-top: 5px;
}

.nameBlock {
  margin-left: 20px;
}

.closeBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.close {
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.priceBlock {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border-radius: 4px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 800;
  background: linear-gradient(90deg,#CF122D, #154284);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 60px;
  margin-top: 30px;
}

.priceRow {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.priceRowLast {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.sum {
  color: #FDC52C;
}

.total {
  text-align: start;
  width: 50%;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.price {
  font-weight: 600;
  color: rgba(253, 197, 44, 1);
  font-size: 20px;
}

.rightBlock {
  width: 80%;
  display: flex;
  justify-content: center;
}

.input {
  width: 80px;
  border: none;
  border-radius: 4px;
  background-color: #46455C;
  height: 30px;
  margin-top: 35px;
  color: white;
  text-align: center;
}

@media (max-width: 490px) {
  .input {
    margin-top: 10px;
  }
  .img {
    width: 100px;
    height: 100px;
  }
  .nameBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .name {
    font-size: 15px;
  }
}

.unpaid {
  color: #CF122D !important;
}

.paid {
  color: green !important;
}

.emptyDesc {
  color: var(--white);
  font-size: 18px;
}

@media (max-width: 360px) {
  .name {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .priceBlock {
    width: 100%;
    padding: 20px 20px;
  }
}
