.customPagination :global(.ant-pagination-item-active) {
  background-color: var(--secondary_dark) !important;
  border-color: gray;
  border-radius: 50%;
}

.customPagination :global(.ant-pagination-item-active a) {
  color: white !important;
}

.customPagination :global(.ant-pagination-item) {
  border-color: gray;
  border-radius: 50%;
}

.customPagination :global(.ant-pagination-item:hover) {
  background-color: var(--secondary_dark) !important;
  border-color: gray;
  border-radius: 50%;
}

.customPagination :global(.ant-pagination-item:hover a) {
  color: white !important;
}

.customPagination :global(.ant-pagination-item-link:hover) {
  background-color: var(--secondary_dark) !important;
  border-color: gray;
  border-radius: 50% !important;
}

.customPagination :global(.ant-pagination-item-link) {
  border-radius: 50% !important;
}

.customPagination :global(.ant-pagination-item-link:hover span) {
  color: white !important;
}

.customPagination :global(.ant-pagination-item-link span) {
  color: var(--secondary_dark) !important;
}
