.block {
  position: relative;
}

.cardBlcok {
  margin: 30px auto 0px;
  width: 97%;
  display: flex;
  overflow-x: auto;
}

.cardBlcok::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

.cardBlcok::-webkit-scrollbar-track {
  background: #666;
}

.cardBlcok::-webkit-scrollbar-thumb {
  background-color: #fbc43f;
  border-radius: 5px;
  cursor: grab;
}

@media (max-width: 600px) {
  .cardBlcok {
    width: 98%;
  }
}

.cardBlcok2 {
  margin-top: 30px;
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.spin {
  width: 100%;
  display: flex;
  justify-content: center;
}

.emptyDesc {
  color: var(--white);
  font-size: 18px;
}

.categoryImage {
  width: 100%;
  height: 600px;
  object-fit: cover;
  object-position: 0px 40px;
  filter: brightness(85%);
}

@media (max-width: 740px) {
  .title {
    font-size: 50px;
    left: 10px;
  }
}

@media (max-width: 1000px) {
  .categoryImage {
    object-position: center;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: 35px;
    left: 10px;
  }
  .categoryImage {
    height: 400px;
    margin-top: -18px;
  }
}
