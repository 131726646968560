.card {
  height: 400px;
  width: 220px;
  margin: 2px;
  background-color: var(--secondary_dark);
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.img {
  height: 330px;
  width: 220px;
  object-fit: cover;
}

.content {
  text-align: start;
  padding: 10px 5px;
}

.name {
  color: white;
  font-size: 18px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ограничиваем содержимое двумя строками */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
  line-height: 22px; /* Высота строки должна соответствовать размеру шрифта */
  height: 23px; /* Максимальная высота, чтобы вместить ровно две строки */
  width: 100%; /* Ширина блока может быть изменена по вашему желанию */
  box-sizing: border-box;
}

.desc {
  color: rgba(170, 170, 170, 1);
  font-size: 14px;
  font-weight: 400;
}

.price {
  color: rgba(207, 18, 45, 1);
  font-size: 20px;
  font-weight: 800;
  padding-top: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.button {
  background: linear-gradient(to right, rgba(21, 66, 132, 1), rgba(207, 18, 45, 1));
  height: 40px;
  font-weight: 800;
  font-style: italic;
  font-size: 18px;
  color: white;
}

.cart {
  position: absolute !important;
  right: 10px;
  top: 10px;
  color: black;
  cursor: pointer;
}
